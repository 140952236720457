.list-notification-answers {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 130px;

	&__button-wrapper {
		width: 100%;
		position: fixed;
		bottom: 25px;
	}
}
