.questions {
	display: flex;
	flex-direction: column;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	padding-top: 69px;

	&.in {
		animation: fadein 0.8s ease;
	}

	&.out {
		animation: fadeout 0.8s ease;
	}

	&-answers {
		height: 100%;
		margin: 25px auto;
		width: 100%;
		max-width: 375px;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@media screen and (max-height: 600px) {
			margin: 15px auto;
		}

		.button {
			margin: 0 auto 30px;
			max-width: 335px;
			width: 100%;
		}
	}
}

@keyframes fadein {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeout {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
