.widget {
	width: 147px;
	height: 100px;
	background: var(--dark--gray--color);
	border: 2px solid var(--warm--gray--color);
	border-radius: 15px;
	transition: all 0.25s;

	display: flex;
	align-items: center;
	justify-content: center;

	&__image {
		width: 110px;
		height: 40px;
	}

	&.selected {
		background: var(--dark--pink--color);
		border: 2px solid var(--pink--color);
	}
}
