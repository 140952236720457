.account {
	max-width: 480px;
	min-width: 320px;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	margin: 0 auto;

	padding-top: 69px;
	padding-bottom: 10px;

	&__toast {
		margin-top: 40px;

		display: flex;
		max-width: 335px;

		background: var(--toast--background--color);
		border: 1px solid rgba(12, 31, 61, 0.01);
		border-radius: 14px;

		padding: 12px 20px 17px;

		&__image {
			width: 20px;
			height: 20px;
			margin: 4px 10px 0 0;
		}

		&__description {
			margin: 0 20px 0 0;
			font-weight: 400;
			font-size: 15px;
			line-height: 22px;

			color: var(--color--black);
		}
	}

	&__header {
		margin: 40px 0 15px;

		font-weight: 600;
		font-size: 26px;
		line-height: 130%;
		color: var(--color--black);
	}

	&__title {
		margin: 40px 0 10px;
		font-weight: 600;
		font-size: 18px;
		line-height: 130%;

		color: var(--color--black);
	}

	&__cancel {
		margin: 10px 0 30px;
		width: 70%;
		font-weight: normal;
		font-size: 18px;
		line-height: 27px;
		text-align: center;
		color: var(--color--black);

		opacity: 0.9;
	}

	&__contacts {
		width: 100%;
		max-width: 320px;
		background: var(--color-blue-grey);
		border-radius: 15px;
		padding: 25px 20px 32px;

		display: flex;

		&__image {
			width: 24px;
			height: 24px;
			margin-right: 6px;
		}

		&__info {
			display: flex;
			flex-direction: column;
			margin-top: 2px;

			&__description {
				margin: 1px 0 5px 0;
				font-weight: 400;
				font-size: 16px;
				line-height: 130%;

				color: var(--color--grey-white);
			}

			&__link {
				text-decoration: none;

				font-weight: 600;
				font-size: 18px;
				line-height: 120%;

				color: var(--color--pink-warm);
			}
		}
	}

	&__description {
		margin: 15px 0 0;

		text-align: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: var(--color--black);

		opacity: 0.9;
	}

	&__link {
		margin-top: 30px;

		font-weight: normal;
		font-size: 14px;
		line-height: 15px;
		letter-spacing: 0.05em;
		text-transform: capitalize;
		color: var(--color--black);

		opacity: 0.8;

		text-decoration: none;
	}

	.button {
		margin: 0 auto;
		width: 100%;
		max-width: 335px;
		padding: 18px 90px;
	}
}
