.button {
	padding: 18px 101px;

	background: var(--pink--color);
	color: var(--white--color);
	border: none;
	border-radius: 40px;

	text-align: center;
	font-size: 15px;
	font-weight: 600;
	line-height: 22px;
	font-family: 'Poppins', sans-serif;

	transition: all 0.35s;

	&-disabled {
		background: var(--disabled--pink--color);
		color: var(--disabled--white--color);
	}
}
