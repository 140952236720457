.bonus-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 80px;
	&__wrapper {
		max-width: 375px;
		width: 100%;
		display: flex;
	}
	&__bonus {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-self: end;
		margin-left: auto;
		margin-bottom: 37px;

		@media screen and (max-height: 700px) {
			margin-bottom: 17px;
		}
		&-img {
			width: 150px;
		}

		&-label {
			font-family: Poppins;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 120%;

			text-align: center;
			text-transform: capitalize;

			color: #ffffff;
			margin-top: -28px;
			margin-left: 16px;
		}
	}

	&__image {
		max-width: 375px;
		margin-bottom: 15px;
		padding: 0 10px;

		@media screen and (max-height: 700px) {
			margin: 0 20px 10px;
			padding: 0 30px;
		}
	}

	&__title {
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		line-height: 38px;

		display: flex;
		align-items: center;
		text-align: center;

		color: #ffffff;

		margin-top: 0px;
		margin-bottom: 10px;
	}

	&__text {
		margin-top: 0;
		margin-bottom: 0;
		width: 250px;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 25px;

		text-align: center;

		color: #ffffff;

		@media screen and (max-height: 700px) {
			line-height: 20px;
		}
	}

	.button {
		margin-top: 30px;
		padding: 14px 30px;

		width: 100%;
		max-width: 335px;
	}
}
