.theme-main {
	margin-bottom: 56px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__title {
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		line-height: 38px;

		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: -0.078px;

		color: #ffffff;

		margin-bottom: 15px;
		width: 270px;
	}

	&__label {
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 25px;

		text-align: center;

		color: #ffffff;
        width: 333px;
        margin-bottom: 30px;
	}

    &__animation{
        width: 375px;
        height: 278px;
        margin-top: 0px;
    }

    &__wrapper {
		width: 375px;
		position: relative;
		height: 0;
	}

	&__mask {
		position: absolute;
		top: -280px;
		height: 286px;
        width: 375px;

		@media screen and (max-width: 375px) {
			height: 280px;
			top: -279px;
		}
	}

	.button {
		margin-top: 30px;
		padding: 14px 30px;

		width: 100%;
		max-width: 335px;
	}
}
