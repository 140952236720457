.review-wrapper {
	margin: 15px 20px;
	border: 1.5px solid var(--pink--color);
	border-radius: 15px;

	.review {
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		padding: 16px 15px;
		border: none;

		&-header {
			display: flex;
			justify-content: space-between;

			&-title {
				display: flex;
				flex-direction: column;

				&-rating {
					width: 80px;
					height: 14px;
				}

				&-content {
					margin: 9px 0 0;
					font-weight: 700;
					font-size: 14px;
					line-height: 18px;
				}
			}

			&-store {
				width: 28px;
				height: 28px;
			}
		}

		&-content {
			margin: 10px 0 0;
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
		}
	}
}
