@keyframes widget1-flow {
	0% {
		left: -100px;
		top: -100px;
	}
	100% {
		left: 135px;
		top: 70px;
	}
}
@keyframes widget2-flow {
	0% {
        left: -100px;
		top: -30px;
	}
	100% {
		left: 13px;
		top: 105px;
	}
}
@keyframes widget3-flow {
	0% {
		left: 350px;
		top: 50px;
	}
	100% {
		left: 150px;
		top: 165px;
	}
}
@keyframes widget4-flow {
	0% {
		left: -20px;
		top: 80px;
	}
	100% {
		left: 75px;
		top: 230px;
	}
}
@keyframes widget5-flow {
	0% {
		left: -60px;
		top: 150px;
	}
	100% {
		left: 240px;
		top: 210px;
	}
}
@keyframes widget6-flow {
	0% {
		left: -50px;
		top: 350px;
	}
	100% {
		left: 230px;
		top: 70px;
	}
}
@keyframes widget7-flow {
	0% {
		left: -50px;
		top: 350px;
	}
	100% {
		left: 195px;
		top: 0;
	}
}
@keyframes textBubble1-flow {
	0% {
		left: -100px;
		top: -100px;
	}
	100% {
		left: 40px;
		top: 0;
	}
}
@keyframes textBubble2-flow {
	0% {
        left: -100px;
		top: -30px;
	}
	100% {
		left: 160px;
		top: 0;
	}
}
@keyframes textBubble3-flow {
	0% {
		left: 350px;
		top: 50px;
	}
	100% {
		left: 250px;
		top: 5px;
	}
}
@keyframes textBubble4-flow {
	0% {
		left: -20px;
		top: 80px;
	}
	100% {
		left: 25px;
		top: 120px;
    }
}
@keyframes textBubble5-flow {
	0% {
		left: -60px;
		top: 150px;
	}
	100% {
		left: 130px;
		top: 85px;
	}
}
@keyframes textBubble6-flow {
	0% {
		left: -50px;
		top: 350px;
	}
	100% {
		left: 245px;
		top: 100px;
	}
}
@keyframes textBubble7-flow {
	0% {
		left: -50px;
		top: 350px;
	}
	100% {
		left: 100px;
		top: 200px;
	}
}
@keyframes textBubble8-flow {
	0% {
		left: -20px;
		top: 80px;
	}
	100% {
		left: 205px;
		top: 195px;
	}
}
