.theme-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 80px; 
	// overflow-y: hidden;

	&__title {
		margin-top: 0px;
		margin-bottom: 15px;
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		line-height: 38px;

		display: flex;
		align-items: center;
		text-align: center;

		color: #ffffff;
	}

	&__text {
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 25px;

		text-align: center;

		color: #ffffff;

		margin-top: 0px;
		margin-bottom: 30px;
		white-space: pre-line;
	}

	&__image {
		width: 375px;
	}

	.button {
		margin-top: 30px;
		padding: 14px 30px;

		width: 100%;
		max-width: 335px;
	}
}
