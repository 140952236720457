.notification {
	background: var(--notification-back--color);
	border: 2px solid transparent;
	border-radius: 15px;
	margin-bottom: 16px;
	transition: all 0.25s;

	&__image {
		width: 311px;
		height: 100px;
	}

	&.selected {
		background: var(--dark--pink--color);
		border: 2px solid var(--pink--color);
		border-radius: 15px;
	}
}
