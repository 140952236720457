.unsubscribe-offer {
	padding-top: 64px;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	font-family: Poppins;
	font-style: normal;
	color: white;
	text-align: center;


	&__title {
		font-weight: 600;
		font-size: 28px;
		line-height: 130%;
		margin: 0 20px 15px;
	}
	&__pink-text{
		color: #FA3269;
	}
	&__img {
		width: 100%;
		margin-bottom: 6px;
	}
	&__price {
		font-weight: 600;
		font-size: 22px;
		line-height: 130%;
		color: white;

		margin: 0 15px 20px;
	}
	&__resubscribe-btn {
		height: 50px;
		max-width: 371px;
		width: calc(100% - 40px);
		padding: 0 20px;
		margin: 0 20px 12px;

		background: #FA3269;
		border: none;
		border-radius: 38px;
		cursor: pointer;

		font-weight: 600;
		font-size: 14px;

		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
	}
	&__unsubscribe-btn {
		height: 50px;
		max-width: 371px;
		width: calc(100% - 40px);
		padding: 0 20px;
		margin: 0 20px 12px;

		background: var(--color-white);
		border: 1px solid #e8e8ea;
		border-radius: 38px;
		cursor: pointer;

		font-weight: normal;
		font-size: 14px;

		display: flex;
		align-items: center;
		justify-content: center;
		color: #E8E8EA;
	}

}
