.discount-main {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-bottom: 70px;

	&__image {
		width: 375px;
		height: 171px;
	}

	&__label {
		width: 302px;
		margin: 30px 0;

		text-align: center;
		font-weight: 400;
		font-size: 15px;
		line-height: 133%;
	}

	&__price {
		margin-top: 30px;

		text-align: center;
		font-weight: 400;
		font-size: 12px;
		line-height: 133%;
		letter-spacing: 0.27px;

		opacity: 0.5;
	}

	.button {
		width: 335px;
		padding: 12px 30px;
	}
}
