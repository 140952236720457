.header-container {
    margin: 15px 0 0;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        align-items: center;
        justify-content: center;

        &-logo {
            margin-right: 5px;
            width: 35px;
        }

        &-title {
            font-size: 14.688px;
            font-weight: 700;
            line-height: 22px;
        }

        &-question-label {
            margin: 15px 0 0;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            opacity: 0.5;
        }
    }
}
