.customize-home-progressbar {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .CircularProgressbar {
        width: 170px;
        height: 170px;
        margin: 50px 0;

        &-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 36px;
            fill: var(--white--color);
        }

        &-path {
            stroke: var(--pink--color);
        }

        &-trail {
            stroke: var(--dark--gray--color);
        }
    }

    &__text {
        flex: 1;

        &__title {
            text-align: center;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }

        &__label {
            display: block;
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 25px;
        }
    }
}