.widget-card {
	margin-bottom: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__image {
		width: 375px;
	}

	&__animation{
		width: 375px;
		height: 276px;
		margin-top: -356px;
		margin-bottom: 70px
	}

	&__video {
		width: 375px;
		height: 311px;
	}

	&__wrapper {
		width: 375px;
		position: relative;
		height: 0;
	}

	&__mask {
		position: absolute;
		top: -312px;
		height: 312px;
	}

	&__title {
		width: 332px;
		text-align: center;
		margin: 29px 0 15px;
		font-weight: 700;
		font-size: 26px;
		line-height: 38px;

		&.phones-a-11{
			width: 252px;
		}
	}

	&__label {
		width: 250px;
		display: block;
		text-align: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 25px;
		margin-bottom: 30px;

		&.video-label {
			width: 325px;
		}

		&.customized-keyboard {
			width: 288px;
		}

		&.customized-keyboard-a {
			width: 288px;
		}
	}

	.button {
		padding: 12px 30px;
		width: 335px;
		box-shadow: 0px 12px 56px rgba(242, 53, 102, 0.52);
	}
}
