.theme-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	height: 100%;

	&__image {
		// height: 395px;
		width: 65%;
		@media screen and (max-height: 850px) {
			width: 44%;
			padding-bottom: 20px;
			// height: 365px;
		}
	}

	&__answers {
		display: flex;
		flex-direction: row;

		margin-top: auto;
		padding-bottom: 15px;
		padding-top: 10px;

		@media screen and (min-height: 700px) and (max-height: 850px) {
			margin-top: 10px;
			padding-bottom: 15px;
		}

		@media screen and (max-height: 700px) {
			margin-top: 5px;
			padding-bottom: 15px;
		}

		&-button {
			height: 60px;
			width: 160px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 600;
			font-size: 15px;
			line-height: 22px;

			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;

			color: #ffffff;
			border-radius: 40px;
			border: 1px solid #fa3269;

			&.Yes {
				background: #fa3269;
				margin-left: 15px;
			}

			&.No {
				background-color: #0e0e0f;
			}

			@media screen and (min-height: 650px) and (max-height: 750px) {
				height: 50px;
			}
		}
	}
}
