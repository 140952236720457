.list-square-answers {
	width: fit-content;
	height: fit-content;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	grid-gap: 16px;
	justify-items: center;
	align-items: center;
	padding-bottom: 130px;
}

.button-wrapper {
	width: 100%;
	position: fixed;
	bottom: 25px;
}
