.discount-offer {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-bottom: 70px;

	&__image {
		width: 375px;
		height: 361px;
	}

	&__title {
		text-align: center;
		margin: 30px 0;

		font-weight: 700;
		font-size: 26px;
		line-height: 135%;
	}

	.button {
		width: 335px;
		padding: 12px 30px;
	}
}
