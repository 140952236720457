.question-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__progress {
		margin-top: 30px;

		@media screen and (max-height: 600px) {
			margin-top: 10px;
		}

		color: var(--pink--color);
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
	}

	&__question {
		margin: 0;
		width: calc(100% - 40px);
		text-align: center;
		white-space: pre-wrap;
		font-weight: 700;
		font-size: 26px;
		line-height: 31px;
	}
}
