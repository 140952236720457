.price {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 80px;

	&.test {
		margin-bottom: 30px;
	}

	&-header {
		display: flex;
		flex-direction: column;

		&.legal {
			flex-direction: column-reverse;
		}

		&-rating {
			display: flex;
			flex-direction: column;
			align-items: center;

			&.legal {
				margin-bottom: 22px;
				flex-direction: row;
				justify-content: center;
				gap: 5px;
			}

			&-stars {
				height: 17px;
				width: 88px;
			}

			&-label {
				font-weight: 400;
				font-size: 13px;
				line-height: 20px;
				opacity: 0.76;
			}
		}

		&-title {
			text-align: center;
			font-weight: 700;
			font-size: 35px;
			line-height: 40px;

			&-gradient {
				background: var(--color--red-orange-gradient-horizontal);
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			&.legal {
				margin: 0 0 10px;
				font-size: 30px;
			}
		}
	}

	&-video-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		height: 436px;
		overflow-y: hidden;
		padding-top: 23px;
	}

	&-video {
		width: 303px;
	}

	&-image-wrapper {
		height: 0px;
	}

	&-image {
		position: relative;
		top: -563px;
		width: 332px;
	}

	&-border {
		height: 2px;
		width: 100%;
		background: var(--color--red-orange-gradient-horizontal);
	}

	&__offer-plans {
		margin-top: 30px;

		.button {
			margin-top: 20px;

			width: 100%;
			max-width: 335px;
		}
	}

	&-info {
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		align-items: center;

		&-title {
			margin: 30px 0 10px;
			font-weight: 700;
			font-size: 26px;
			line-height: 35px;

			&.test {
				width: 335px;
				text-align: center;
				margin-top: 10px;
			}
		}

		&-subtitle {
			font-weight: 200;
			font-size: 16px;
			line-height: 31px;
			opacity: 0.5;
		}

		&-offers {
			width: 340px;
			margin: 30px 0;
			display: grid;
			grid-template-columns: 20px auto;
			grid-auto-rows: auto;
			gap: 10px 15px;

			.pick-icon-wrapper {
				margin-top: 5px;
				width: 20px;
				height: 20px;

				display: flex;
				justify-content: center;
				align-items: center;

				border-radius: 50%;
				background: var(--color--red-orange-gradient-horizontal);

				&-themes {
					margin-top: 5px;
					width: 20px;
					height: 20px;

					display: flex;
					justify-content: center;
					align-items: center;

					border-radius: 50%;
					background: var(--color--red-orange-gradient-horizontal);
					background: #f23566;

					.pick-icon {
						width: 9px;
						height: 7px;
					}
				}

				.pick-icon {
					width: 9px;
					height: 7px;
				}

				.price-offer {
					font-weight: 500;
					font-size: 16px;
					line-height: 140%;
					color: var(--color--black);
					opacity: 0.9;
				}
			}
		}
	}

	.button {
		width: 335px;
		padding: 12px 30px;
	}

	&-additional {
		margin: 30px 0 0;
		display: block;
		text-align: center;
		width: 330px;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.273195px;
		color: #d6d6d6;

		&.test {
			margin-top: 0;
			opacity: 0.8;
		}

		.text-link {
			color: var(--white--color);
		}

		&-bold {
			font-weight: 700;
			color: white;
		}
	}
}

.price-offer-link {
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	color: var(--white--color);
	opacity: 0.9;
}
