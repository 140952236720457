.circle {
	position: absolute;
	border-radius: 50%;
	background: var(--dark--gray--color);
	border: 0.873px solid var(--warm--gray--color);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.25s;

	&-image {
		object-fit: contain;
	}

	&-text {
		color: var(--gray--color);
		font-weight: 500;
		font-size: 12px;
		line-height: 120%;
	}

	&.selected {
		border-color: var(--pink--color);
		background: var(--dark--pink--color);
	}
}
