.stats-card{
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title{
        width: 305px;
        font-size: 26px;
        line-height: 35px;
        text-align: center;
        color: white;
        margin: 0 0 45px 0;
        padding: 0 10px;
    }

    &__image{
        width: 375px;
    }
}