.error {
	background: var(--background--color);
	color: var(--white--color);
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 69px;
	&-image {
		margin-top: 38px;
		width: 375px;
		height: 158px;
	}

	&-title {
		font-weight: 600;
		font-size: 30px;
		line-height: 36px;
		margin: 0 0 10px;
	}

	&-label {
		width: 345px;
		margin-bottom: 20px;
		display: block;
		text-align: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 25px;
		letter-spacing: 0.25px;
		color: var(--white--color);
	}

	.button {
		padding: 12px 30px;
		width: 315px;
	}
}
