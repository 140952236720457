.upsale {
	display: flex;
	flex-direction: column;
	align-items: center;

	padding-top: 70px;
	height: 100vh;

	&-top {
		overflow: auto;
		margin: 0 auto;
		max-width: 480px;
	}
}
