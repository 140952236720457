.sale {
	display: flex;
	flex-direction: column;
	padding-top: 69px;
	height: 100vh;
	overflow: auto;
	overflow-x: hidden;

	.top-1-app {
		margin: 100px 0 90px;

		display: flex;
		flex-direction: column;
		align-items: center;

		&__phones {
			width: 375px;
			height: 358px;
		}

		&__title {
			margin: 30px 0 0;
			text-align: center;
			font-weight: 700;
			font-size: 30px;
			line-height: 38px;
			width: 250px;
		}

		&__label {
			display: block;
			text-align: center;
			margin: 15px 0 0;
			font-weight: 400;
			font-size: 16px;
			line-height: 25px;
			width: 230px;
		}
	}

	.top-2-app-title {
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		line-height: 34px;
		/* or 131% */

		display: flex;
		align-items: center;
		text-align: center;

		color: #ffffff;
		margin: 10px auto 0;
	}
	&.back2 {
		padding-top: 0px;
		background: var(--payment--primary--font--color);

		&.sale-horizontal {
			height: 100vh;
			overflow: auto;
			overflow-x: hidden;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			padding-top: 0px;
			padding-bottom: 30px;
		}
	}

	&.blue {
		background: #090e18;
	}
}
