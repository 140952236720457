.timer-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-bottom: 30px;

	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	color: #ffffff;

	&__wrapper {
		width: 335px;
		height: 124px;

		background: #0d0107;
		border-radius: 20px;
		z-index: 0;
		box-shadow: 0px 0px 20px 10px rgba(46, 54, 71, 0.7);
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		justify-content: center;
	}

	&__title {
		font-size: 19px;
		line-height: 1;
		margin: 0;
		margin-bottom: 12px;
	}
	&__time {
		font-size: 35px;
		line-height: 114%;
		margin: 0;
	}
	&__info {
		font-weight: normal;
		font-size: 12px;
		line-height: 142%;
		margin: 0;
		opacity: 0.9;
	}
}
