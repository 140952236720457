.keyboard-answers {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__example {
		width: 100%;
		position: relative;

		&__back {
			position: absolute;
			right: 0;
			width: 255px;
			height: 150px;
			background: var(--background--color);
			filter: blur(100px);
		}
		&__wrapper {
			margin: 0 auto;
			width: 255px;
			position: relative;
			padding: 1px;
			border-radius: 20px;
			background: var(--color--white-black-vertical);

			&__theme {
				border-radius: 20px;
				position: absolute;
				top: 1px;
				right: 1px;
				left: 1px;
				bottom: -1px;
				width: 253px;
				height: 167px;
			}

			&__keyboard {
				position: relative;
				padding: 17px 20px;
				border-radius: 20px;

				&__buttons {
					width: 219px;
					height: 126px;
				}
			}
		}
	}

	&__widgets-list {
		margin: 40px 0 150px;
		width: fit-content;
		height: fit-content;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		grid-gap: 16px;
		justify-items: center;
		align-items: center;
	}

	&__button-wrapper {
		width: 100%;
		position: fixed;
		bottom: 25px;
	}
}
