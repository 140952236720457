.answer-image {
	width: 147px;
	height: 100px;
	border-radius: 15px;
	border: 2px solid transparent;
	display: flex;
	align-items: center;
	justify-content: center;

	&__image {
		width: 145px;
		height: 98px;
		border-radius: 15px;
	}

	&.selected {
		border: 2px solid var(--pink--color);
		border-radius: 15px;
	}
}
