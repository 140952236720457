.header2 {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__title {
		margin: 20px auto 15px;
		width: 70%;

		@media screen and (max-height: 700px) {
			margin: 15px auto 10px;
		}

		text-align: center;
		font-weight: 600;
		font-size: 24px;
		line-height: 129%;
	}

	&__list {
		padding: 0 33px;
		max-width: 375px;

		&__item {
			display: flex;

			&__pick {
				width: 9px;
				height: 7px;
				margin: 8px 15px 0 0;
			}

			&__text {
				margin: 0;

				font-weight: normal;
				font-size: 16px;
				line-height: 156%;
			}
		}
	}
}
