.input-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;

	margin: 30px 0 20px;
	padding: 1px;
	width: 100%;
	max-width: 335px;

	background: var(--input--gray--color);
	border-radius: 13px;

	&:focus-within {
		background: var(--color--red-orange-gradient-horizontal);
	}

	.input {
		padding: 15px 30px;
		width: 100%;

		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 16px;
		line-height: 22.5px;
		color: var(--input--gray--color);

		background: var(--background--color);
		border-radius: 13px;
		border: none;

		&:focus {
			outline: none;
			color: var(--pink--color);
			font-size: 16px;
		}
	}
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px var(--background--color) inset !important;
	-webkit-text-fill-color: var(--input--gray--color) !important;

	&:focus {
		-webkit-text-fill-color: var(--pink--color) !important;
	}
}
