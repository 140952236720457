.step {
	background: var(--pastel--yellow--color);
	border-radius: 10px;
	padding: 4px 17px;
	width: 105px;

	&-content {
		color: var(--payment--primary--font--color);
		display: block;
		text-align: center;
		font-weight: 600;
		font-size: 22px;
		line-height: 29px;
	}
}
