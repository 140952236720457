.unsubscribe-qustion {
	display: flex;
	flex-direction: column;
	align-items: center;

	height: 100%;
	padding-top: 69px;
	overflow: visible;

	&__header {
		padding-top: 16px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		line-height: 34px;

		font-family: Poppins;
		font-style: normal;
		font-weight: 600;
		line-height: 130%;
		text-align: center;

		color: var(--color--dark-grey);

		&-title {
			margin-bottom: 20px;
			padding: 0 20px;
			font-size: 26px;
		}
		&-subtitle {
			min-height: 26px;
			width: calc(100% - 40px);
			margin: 0 20px 20px;
			

			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 500;
			line-height: 25.5px;
			color: white;
			font-size: 17px;
		}
	}
	&__answers {
		position: relative;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 22px;
		color: white;
		width: 100%;
		opacity: 0.9;
		padding-bottom: 10px;
		&-item {
			min-height: 62px;
			width: calc(100% - 40px);
			padding: 20px;
			margin: 0 20px 10px;

			background: rgba(255, 255, 255, 0.06);
			border: 1px solid rgba(95, 98, 101, 0.6);
			box-sizing: border-box;
			border-radius: 13px;

			cursor: pointer;

			transition: all 0.35s;

			&--selected {
				background: rgba(228, 48, 102, 0.2);
				border: 1.5px solid #F12864;
				box-sizing: border-box;
				border-radius: 13px;
				color: #FA3269;
			}
		}

		&-input {
			-webkit-appearance: none;
			position: relative;
			width: calc(100% - 40px);
			height: 62px;
			padding: 0px 20px;
			resize: none;
			margin: 0 20px;
			border-top: 24px solid transparent;
			border-bottom: 24px solid transparent;
			border-right: 0px solid transparent;
  			border-left: 0px solid transparent;
			background: rgba(255, 255, 255, 0.06);
			-webkit-box-shadow: 0px 0px 0px 1px rgba(34, 79, 124, 0.6) !important;
			box-shadow: 0px 0px 0px 1px rgba(95, 98, 101, 0.6);
			box-sizing: border-box;
			border-radius: 13px;
			outline: none;

			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 22px;
			color: #FA3269;

			opacity: 0.9;

			&:focus,
			&:active {
				-webkit-appearance: none;
				background: var(--background--color);
				-webkit-box-shadow: 0 0 0 1.5px #F12864 !important; 
				box-shadow: 0 0 0 1.5px #F12864;
				box-sizing: border-box;
				border-radius: 13px;
				outline: none;
			}
		}
		&-input::placeholder {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 15px;
			line-height: 22px;

			color: white;

			opacity: 0.9;
			transition: opacity 0.3s ease;
		}
		&-input:focus::placeholder{
			opacity: 0; 
			transition: opacity 0.3s ease;
		}
		
		&-hover {
			position: absolute;
			top: 165px;
			left: 41px;
			height: 0;

			padding-top: 0;
			width: calc(100% - 82px);
			font-weight: normal;
			font-size: 12px;
			line-height: 18px;
			color: #FA3269;

			opacity: 0;
			overflow: hidden;
			transition: all 0.3s ease-in-out;


			&--active {
				padding-top: 5px;
				padding-bottom: 5px;
				top: 149px;
				height: 25px;
				color: rgba($color: #ffffff, $alpha: 0.3);
				opacity: 0.9;
				z-index: 2;
			}
			

			&--focus {
				padding-top: 5px;
				padding-bottom: 5px;
				top: 149px;
				height: 25px;
				color: rgba($color: #ffffff, $alpha: 0.3);
				opacity: 0.9;
			}
		}
	}
	&__btns {
		margin-top: auto;
		width: 100%;

		background: var(--color--white);
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.button {
			width: 100%;
		}

		&-skip {
			font-weight: normal;
			font-size: 14px;

			display: flex;
			align-items: center;
			text-align: center;
			text-transform: capitalize;

			color: var(--color--dark-grey);

			border: none;
			background: var(--color--white);
			padding: 5px 25px;
			opacity: 0.8;

			cursor: pointer;

			margin: 15px 0;
		}
	}
}
