.success {
	background: var(--background--color);
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 69px;

	&-title {
		font-weight: 600;
		font-size: 30px;
		line-height: 36px;
		margin: 52px 0 10px;
	}

	&-label {
		margin-bottom: 30px;
		width: 273px;
		display: block;
		text-align: center;
		font-weight: 400;
		font-size: 15px;
		line-height: 25px;
		letter-spacing: 0.25px;
	}

	&-border {
		width: 100%;
		min-height: 1px;
		background: var(--border--dark--color);
		opacity: 0.1;
		margin-bottom: 40px;
	}

	&-instruction {
		display: flex;
		flex-direction: column;
		align-items: center;

		&-title {
			font-weight: 600;
			font-size: 30px;
			line-height: 36px;
			margin: 0 0 29px;
		}

		&-label {
			width: 300px;
			display: block;
			text-align: center;
			font-weight: 600;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0.25px;
			margin: 20px 0 15px;
		}

		&-images {
			display: flex;
			margin-bottom: 65px;

			img {
				width: 159px;
				height: 47px;
			}
		}

		&-email {
			margin: 5px 0 40px;
			width: 375px;
			height: 341px;
		}
	}
}
