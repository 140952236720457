.email-footer {
	// margin: 20px 0;
	height: 100%;
	margin: 20px 45px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	max-width: 335px;

	&.check {
		margin: 20px 20px;
	}

	&__content {
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		opacity: 0.6;

		&__link {
			color: var(--white--color);
		}
	}

	&__agreement {
		display: flex;
		flex-direction: column;

		&__row {
			display: flex;

			&__checkbox {
				height: 15px;
				width: 15px;
				margin: 3px 10px 0 0;
			}

			&__text {
				width: 70%;

				margin-bottom: 15px;
				text-align: left;
				font-weight: 400;
				font-size: 10px;
				line-height: 130%;
				letter-spacing: 0.25px;

				color: var(--checkout--secondary--font--color);

				&__link {
					text-decoration: none;

					color: var(--pink--color);
				}
			}
		}
	}
}
