.show {
	transition: padding 1s ease-in-out, background 1s ease-in-out;
	background: rgba(0, 0, 0, 0.4);
	padding-top: 0;
	z-index: 999;
}

.hide {
	transition: padding 1s ease-in-out, z-index 1.5s ease-in-out,
		background 1s ease-in-out;
	background: rgba(0, 0, 0, 0);
	padding-top: 100vh;
	z-index: -999;
}

.backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	transition: 1.1s ease-in-out;
}

.modal {
	position: relative;
	display: block;
	margin-top: 16px;
	border-radius: 8px 8px 0px 0px;
	background: var(--white--color);
	color: var(--checkout--primary--font--color);
	max-width: 475px;
	width: 475px;
	overflow-y: auto;

	.wrapper {
		background: var(--white--color);
		position: fixed;
		z-index: 999;
		max-width: 475px;
		width: 100%;
	}

	&__header {
		padding: 17px 0 30px;

		&__btn {
			display: flex;
			flex-direction: column;
			align-items: center;

			&-line {
				background: var(--modal--button--color);
				width: 40px;
				height: 2.33px;
				border-radius: 1.5px;
				opacity: 0.5;
			}

			&-close {
				width: 14.28px;
				height: 14.05px;
				align-self: flex-end;
				margin-right: 20px;
			}
		}

		&__title {
			margin: 22px auto 16px;
			text-align: center;
			font-weight: 600;
			font-size: 23px;
			line-height: 28px;
			width: 310px;
		}
	}

	&__choice {
		margin: 0 32px;
		display: flex;
		flex-direction: column;
		align-items: center;

		&__card {
			width: 100%;
			border-radius: 12px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 19px 20px;
			margin-bottom: 8px;

			background: var(--choice--background--color);
			border: 1px solid var(--choice--border--color);

			.content {
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
				color: var(--checkout--secondary--font--color);
				display: flex;

				span {
					display: block;
					align-self: center;
				}

				.paypal-image {
					width: 85px;
					height: 20px;
					margin-left: 10px;
				}

				.credits-image {
					width: 123px;
					height: 24.6px;
					margin-left: 8px;
				}
			}

			.check {
				background: var(--white--color);
				border-radius: 38px;
				border: 1.5px solid var(--check--border--color);
				display: flex;
				align-items: center;
				justify-content: center;
				width: 20px;
				height: 20px;

				.check-icon {
					width: 10.2px;
					height: 8.12px;
				}
			}

			&.active-payment {
				background: var(--white--color);
				border: 2px solid var(--pink--color);
				box-shadow: 0px 4px 14px var(--shadow--color);
				background: var(--white--color);

				.content {
					color: var(--pink--color);
				}

				.check {
					border: none;
					background: var(--pink--color);
				}
			}
		}
	}

	&__price {
		color: var(--checkout--secondary--font--color);
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		width: 100%;

		&__annual-plan {
			display: flex;
			justify-content: space-between;
			margin-bottom: 6.5px;

			&__price {
				font-weight: 600;
				font-size: 18px;
			}
		}

		&__discount {
			display: flex;
			justify-content: space-between;

			&__price {
				color: var(--blue--color);
				font-weight: 600;
				font-size: 24px;
			}
		}

		&__total {
			display: flex;
			justify-content: space-between;
			margin-top: 22px;

			color: var(--pink--color);
			font-weight: 600;
			font-size: 24px;

			&__price {
				color: var(--pink--color);
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 312px 32px 0;
		height: fit-content;
	}

	&__paypal {
		width: 311px;
	}

	&__privacy {
		display: block;
		text-align: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 47px;
		letter-spacing: 0.56px;
	}
}

.show-payment-type {
	display: none;
}

#solid-payment-form-container {
	min-height: 300px;
	height: 300px;
	width: 100%;
}

#solid-payment-form-iframe {
	width: 100%;
	height: 300px;
	margin-top: 25px;
	border: none;
}
