.awards {
	margin-bottom: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__apple-winner {
		width: 115px;
	}

	&__title {
		margin: 15px 0;
		width: 85%;
		text-align: center;
		font-weight: 700;
		font-size: 22px;
		line-height: 159%;
	}

	&__image {
		width: 100%;
		max-width: 375px;
	}
}
