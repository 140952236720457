.pdfWrapper {
	width: 100%;
	position: absolute;
	top: 69px !important;

	.pdfViewer {
		width: 100%;

		.page {
			margin: 0;

			width: 100% !important;

			border: none;

			.canvasWrapper {
				width: 100% !important;
			}

			.textLayer {
				width: 100% !important;
			}
		}
	}
}
