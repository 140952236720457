.unsubscribe-success {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	&__img {
		max-width: 400px;
		width: 100%;
	}
	&__text {
		max-width: 330px;
		margin: 5px 20px 80px;

		font-family: Poppins;
		font-style: normal;
		font-weight: 600;
		font-size: 28px;
		line-height: 130%;
		text-align: center;

		color: var(--color--dark-grey);
	}
}
