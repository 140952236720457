.container {
	width: 151px;
	height: 162px;
	background: rgba(255, 255, 255, 0.06);
	mix-blend-mode: normal;
	border: 1px solid rgba(95, 98, 101, 0.6);
	box-sizing: border-box;
	border-radius: 20px;
	padding: 8px;

	&-answer {
		width: 135px;
		height: 146px;
		margin-bottom: -40px;
		image-rendering: pixelated;
		-ms-interpolation-mode: nearest-neighbor;
	}

	&-text {
		font-family: Poppins;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 25px;
		text-align: center;

		color: #ffffff;
	}

	&.selected {
		background: #391420;
		border: 1px solid #fa3269;
	}
}
