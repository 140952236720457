.offer-plan__border {
	width: 100%;
	max-width: 375px;

	padding: 1px;
	margin-bottom: 18px;
	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;

	background: var(--price--gray--color);
	border-radius: 13px;

	&.active {
		background: var(--pink--color);
	}

	.bestseller {
		width: fit-content;
		padding: 3px 14px;

		background: var(--pink--color);
		border-radius: 6px;

		color: var(--white--color);
		font-weight: 600;
		font-size: 11px;
		line-height: 15px;

		position: absolute;
		top: -10%;
	}

	.offer-plan {
		width: 100%;

		// display: flex;
		// justify-content: space-around;

		display: grid;
		grid-template-rows: 1fr;
		grid-template-columns: auto 1fr auto;

		padding: 19px;
		background: var(--background--color);
		border-radius: 13px;

		&__emoji {
			width: 30px;
			height: 30px;
			align-self: center;
			margin-right: 25px;
		}

		&__period {
			display: flex;
			flex-direction: column;

			&__time {
				margin: 0;

				font-weight: 700;
				font-size: 24px;
				line-height: 125%;
				text-transform: uppercase;
			}

			&__price {
				margin: 0;

				font-weight: normal;
				font-size: 13px;
				line-height: 18px;
				color: var(--color--grey-dark);
			}
		}

		&__price {
			position: relative;
			display: flex;
			align-items: center;

			margin-right: 11px;

			&__back {
				width: 119px;
				height: 54px;

				position: absolute;
				top: 0;
				bottom: 0;
				right: -20px;

				z-index: 1;
			}

			&__value {
				z-index: 2;

				font-weight: 600;
				font-size: 18px;
				line-height: 25px;

				display: flex;

				&__currency {
					margin: 0;

					&.active {
						background: var(--color--red-orange-gradient-horizontal);
						background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}

				&__accent {
					font-size: 35px;
					line-height: 1;
					margin-top: 1px;

					&.active {
						background: var(--color--red-orange-gradient-horizontal);
						background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}

				&__subtext {
					display: flex;
					flex-direction: column;

					&__coins {
						margin: 0;

						height: 20px;

						&.active {
							background: var(--color--red-orange-gradient-horizontal);
							background-clip: text;
							-webkit-text-fill-color: transparent;
						}
					}

					&__description {
						margin: 0;

						font-weight: 400;
						font-size: 11px;
						line-height: 15px;

						color: var(--color--grey-dark);
					}
				}
			}
		}
	}
}
