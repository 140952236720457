.footer {
	display: flex;
	flex-direction: column;
	align-items: center;

	&-guarantee {
		margin: 0 0 35px;
		max-width: 270px;

		&-title {
			margin: 0 0 30px;
			font-weight: 600;
			font-size: 25px;
			line-height: 31px;
			text-align: center;
		}

		&-label {
			margin: 0 0 15px;
			display: block;
			text-align: center;
			font-weight: 500;
			font-size: 15px;
			line-height: 19px;
		}
	}

	&-title {
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		line-height: 34px;
		/* or 131% */

		display: flex;
		align-items: center;
		text-align: center;

		color: #ffffff;
		margin: 20px auto 30px;
		margin-top: 20px;
		margin-bottom: 30px;
	}

	&-payment {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 6px;

		&-secure {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;

			&-icon {
				width: 25px;
				height: 24px;
				margin-right: 4px;
			}

			&-title {
				font-weight: 700;
				font-size: 15px;
				line-height: 18px;
				letter-spacing: 0.248359px;
			}
		}

		&-image {
			width: 291.6px;
			height: 93.6px;
		}
	}

	&-general {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 19px;

		&-links {
			width: 290px;
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;

			.link {
				color: var(--white--color);
				font-weight: 500;
				font-size: 12px;
				line-height: 19px;
				letter-spacing: 0.248359px;
				text-decoration: underline;
				cursor: pointer;

				opacity: 0.9;
			}
		}

		&__money-back {
			margin: 35px 20px 70px;
			padding: 10px;

			display: flex;
			align-items: center;

			max-width: 375px;

			background: var(--money--back--background--color);
			box-shadow: 0px 4px 23px rgba(12, 31, 61, 0.1);
			border-radius: 14px;

			position: relative;

			&__wrapper {
				padding: 2px;
				background: var(--color--red-orange-gradient-horizontal);
				border-radius: 14px;

				&__content {
					padding: 30px 19px 20px;

					width: 100%;
					height: 100%;
					background: var(--money--back--background--color);
					border-radius: 14px;

					&__title {
						margin: 0;
						font-weight: 600;
						font-size: 25px;
						line-height: 125%;
						text-align: center;
					}

					&__text {
						margin: 15px 0 0;

						font-weight: 400;
						font-size: 12px;
						line-height: 18px;
						text-align: center;

						&__accent {
							font-weight: 600;
						}

						.link {
							font-weight: 500;
							color: var(--white--color);
						}
					}
				}
			}

			&__rosette {
				width: 63px;
				height: 87px;

				position: absolute;
				right: 20px;
				bottom: -50px;
			}
		}

		&-copyright {
			width: 270px;
			display: block;
			text-align: center;
			font-weight: 400;
			font-size: 12px;
			line-height: 19px;
			letter-spacing: 0.248359px;

			opacity: 0.6;
		}
	}
}
