.header-sale {
	position: sticky;
	top: 0;
	z-index: 10;

	display: flex;
	justify-content: space-between;

	background: var(--background--color);

	&__container {
		margin: 15px 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		&__logo {
			margin-right: 5px;
			width: 35px;
			height: 35px;
		}
	}

	&__button-wrapper {
		margin: 10px 20px;

		.button {
			padding: 12px 30px;
		}
	}

	&.back2 {
		background: var(--payment--primary--font--color);
	}
}
