.square-themes__list {
	width: fit-content;
	height: fit-content;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-gap: 15px;
	justify-items: center;
	align-items: center;
	padding-bottom: 130px;
}
