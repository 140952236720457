.circles-answers {
	display: flex;
	flex-direction: column;
	width: 100%;

	button {
		margin-bottom: 10px !important;
		z-index: 1000;
	}
	.circles-answer-wrapper {
		overflow: auto;
		flex: 1;
		position: relative;
		padding-bottom: 60px;
	}
}
