.discount-header {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__animation {
		margin-top: 25px;

		width: 110px;
		height: 91px;
	}

	&__title {
		text-align: center;
		margin: 15px 0 0;
		width: 250px;

		font-weight: 700;
		font-size: 35px;
		line-height: 114%;

		background: var(--color--red-orange-gradient-horizontal);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
