.horizontal-footer {
	padding-top: 15px;
    padding-bottom: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	// bottom: 0;
	border-top: 1px solid #f23566;
	// position: fixed;
	width: 100%;
	background: var(--payment--primary--font--color);

	.button {
		padding: 14px 30px;

		width: 100%;
		max-width: 335px;
	}
}
