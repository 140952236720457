.upsale-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	&__skip-button {
		margin: 10px 0 20px;

		@media screen and (max-height: 700px) {
			margin: 7px 0 12px;
		}

		border: none;
		background: none;

		font-family: Poppins;
		font-weight: 400;
		font-size: 12px;
		line-height: 225%;
		letter-spacing: 0.25px;
		color: var(--white--color);

		opacity: 0.8;
	}
	&__price {
		margin: 13px auto 14px;

		@media screen and (max-height: 700px) {
			margin: 7px auto 14px;
		}

		font-weight: 400;
		font-size: 15px;
		line-height: 22px;

		.accent {
			font-weight: 700;
		}
	}
}

.button {
	padding: 17px 20px;
	width: 100%;

	@media screen and (max-height: 700px) {
		padding: 14px 20px;
	}
}
