.navbar {
	padding: 10px 20px 10px;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: space-between;

	&-container {
		z-index: 10;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;

		background: #0e0e0f;

		transition: background 0.5s;

		&--opened-burger {
			background: #0e0e0f !important;
		}

		.header-timer{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 0px 10px;
		}

		.timer {
			padding: 10px 0 10px;
			display: flex;
			flex-direction: column;
			align-items: center;

			&__title {
				text-align: center;
				font-size: 12px;
				font-weight: 600;
				line-height: 17px;
				color: white;
				margin-bottom: 5px;
			}

			&__value {
				font-style: normal;
				font-weight: bold;
				font-size: 32px;
				line-height: 1;

				background: var(--pink--color);
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.button {
			margin: 10px 0;
			padding: 13px 45px;
		}
	}

	.back-button {
		width: 47.77px;
		height: 49px;
		display: flex;
		align-items: center;

		user-select: none;

		transition: opacity 0.35s ease-in-out;
		opacity: 1;

		&__text {
			font-size: 14px;
			line-height: 21px;
		}

		@media screen and (min-width: 1024px) {
			cursor: pointer;
		}
	}

	.navbar-logo {
		display: flex;
		align-items: center;

		img {
			width: 36px;
			height: 36px;
			margin-right: 6px;
		}

		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 14.688px;
		line-height: 22px;

		color: #ffffff;
	}

	.burger-icon {
		padding: 6px 0;
		object-fit: contain;

		margin-left: 26px;

		display: grid;
		gap: 5px;
		grid-template-rows: auto auto;

		user-select: none;

		@media screen and (min-width: 1024px) {
			cursor: pointer;
		}

		&__line {
			width: 22px;
			height: 2px;

			transition: transform 0.35s;
		}

		&--open {
			.burger-icon__line--bottom {
				transform: translate(0, -7px) rotate(45deg);
			}

			.burger-icon__line--top {
				transform: rotate(-45deg);
			}
		}
	}
}

.navbar-back-button--hide {
	cursor: auto !important;

	opacity: 0 !important;
}
