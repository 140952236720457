.payment-header {
    display: flex;
    align-items: center;
    margin: 66px 0 0;

    &__logo {
        width: 58.9px;
        height: 59px;
        margin-right: 8.41px;
    }

    &__title {
        font-weight: 700;
        font-size: 24.8227px;
        line-height: 37px;
    }
}