.email-form {
	display: flex;
	flex-direction: column;
	// align-items: center;
	max-width: 335px;
	width: 100%;

	&__image {
		width: 375px; 
		max-width: 375px;
		margin-left: -20px;
	}

	&__title {
		display: block;
		text-align: center;
		font-weight: 700;
		font-size: 30px;
		line-height: 31px;
		margin: 20px 0 15px;
	}

	&__label {
		display: block;
		text-align: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		width: 230px;
		opacity: 0.9;
		margin: 0 auto;
	}
}
