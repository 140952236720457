:root {
	--background--color: #0e0e0f;
	--payment--primary--font--color: #090e18;
	--payment--secondary--font--color: rgba(9, 14, 24, 0.8);
	--checkout--primary--font--color: #191919;
	--toast--background--color: #22262f;
	--checkout--secondary--font--color: #808897;
	--choice--background--color: #f7f8fb;
	--choice--border--color: #e1e1e1;
	--check--border--color: #e7e8ea;
	--notification-back--color: rgba(255, 255, 255, 0.8);
	--shadow--color: rgba(34, 36, 39, 0.12);
	--white--color: #ffffff;
	--pastel--yellow--color: #fbebb3;
	--pink--color: #fa3269;
	--dark--pink--color: rgba(228, 48, 102, 0.2);
	--gray--color: #bababc;
	--modal--button--color: #b2b2b2;
	--dark--gray--color: rgba(255, 255, 255, 0.06);
	--warm--gray--color: rgba(95, 98, 101, 0.6);
	--input--gray--color: #878b96;
	--price--gray--color: #7a7a85;
	--border--dark--color: #222427;
	--blue--color: #4380ff;
	--color--red-orange-gradient-horizontal: linear-gradient(
		270deg,
		#fa3469 0%,
		#fb9c70 100%
	);
	--color--white-black-vertical: linear-gradient(
		180deg,
		#ffffff 0%,
		rgba(255, 255, 255, 0) 100%
	);
	--color--conic: conic-gradient(
		from 179.8deg at 50% 50%,
		#4939c1 0deg,
		#de4aa3 86.25deg,
		#ff8c6a 230.62deg,
		#e3d8c2 273.75deg,
		#4939c1 360deg
	);
	--color--violet-pink-orange-horizontal: linear-gradient(
		270deg,
		#9885ff -34.34%,
		#fa3469 11.21%,
		#fb9c70 100%
	);
	--money--back--background--color: #121926;
	--disabled--pink--color: #830f2f;
	--disabled--white--color: #b99595;
}
