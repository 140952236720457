.unsubscribe-screen {
	width: 100%;
	max-width: 480px;
	min-width: 320px;
	margin: 0 auto;

	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	overflow: auto;
}

.unsubscribe-screen::-webkit-scrollbar { width: 0 !important }

.unsubscribe-screen--active {
	transition: opacity 0.35s ease-in-out;
	opacity: 1;
}

.unsubscribe-screen--unactive {
	transition: opacity 0.35s ease-in-out;
	opacity: 0;
}
