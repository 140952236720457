.slider {
	flex: 2;
	width: 375px;
	margin-bottom: 90px;

	@media screen and (max-width: 375px) {
		flex: 2;
	}

	@media screen and (min-width: 813px) {
		flex: 2;
		margin-top: 20px;
	}
}

.title-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.title {
	width: 332px;
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	line-height: 38px;
	text-align: center;

	color: #ffffff;
	margin-top: 10px;
	margin-bottom: 50px;
}

.swiper-slide {
	position: relative;
	top: 0;
	left: -20px;

	text-align: center;
	font-size: 18px;

	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	transition: all 0.7s !important;

	&-prev {
		left: -75px;
		opacity: 0.5;
		top: 0;
		height: 90%;
		transform: scale(0.9);
		z-index: 2;
	}

	&-active {
		top: 0px;
		z-index: 10;
	}

	&-next {
		top: 0;
		height: 90%;
		transform: scale(0.9);
		z-index: 2;
		left: 30px;
		opacity: 0.5;
	}

	@media screen and (min-width: 910px) {
		padding-top: 20px;
	}
}

.swiper-slide img {
	display: block;
	height: 387px;
	object-fit: contain;
}

.swiper-container {
	margin-left: auto;
	margin-right: auto;
	min-height: 400px;

	.swiper-pagination {
		top: 374px !important;
		height: 20px;

		&-bullet {
			background: rgba(242, 53, 102, 0.5) !important;
			opacity: 1 !important;
			transition: all 0.7s !important;
			z-index: 10;

			@media screen and (max-width: 486px) {
				margin: 0 2.2px !important;
			}

			&-active {
				width: 30px !important;
				border-radius: 15px;
				background: rgba(242, 53, 102, 1) !important;

				@media screen and (max-width: 486px) {
					width: 35px !important;
				}
			}
		}

		// &-bullets{
		// 	bottom: 0;
		// }

		// @media screen and (min-width: 350px) {
		// 	top: 66% !important;
		// }

		// @media screen and (min-width: 463px) {
		// 	top: 70% !important;
		// }

		// @media screen and (min-width: 650px) {
		// 	top: 76% !important;
		// }

		// @media screen and (min-width: 820px) {
		// 	top: 83% !important;
		// }

		// @media screen and (min-width: 950px) {
		// 	top: 90% !important;
		// }
	}

	.swiper-wrapper {
		height: 387px;
	}
}

.button {
	padding: 14px 30px;
	align-self: center;
	width: 100%;
	max-width: 335px;
}
