* {
	box-sizing: border-box;
}

html,
body {
	width: 100%;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	color: var(--white--color);
	background: var(--background--color);
	font-family: 'Poppins', sans-serif;
}

.app {
	min-height: 100vh;
	overflow-x: hidden;
}

.accent {
	color: var(--pink--color);
}

.r-30 {
	right: 30px;
}
