.header1 {
	text-align: center;

	&__title {
		margin: 20px auto 15px;
		@media screen and (max-height: 700px) {
			margin: 15px auto 10px;
		}
		font-weight: 600;
		font-size: 24px;
		line-height: 129%;
	}

	&__text {
		margin: 0 auto;
		width: 70%;

		font-weight: 400;
		font-size: 16px;
		line-height: 156%;
	}
}
