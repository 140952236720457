.main {
	display: flex;
	flex-direction: column;
	align-items: center;

	&-title {
		width: 320px;
		display: block;
		text-align: center;

		margin: 60px 21px 34px;

		font-weight: 700;
		font-size: 35px;
		line-height: 40px;
		letter-spacing: -0.078px;

		background: var(--color--red-orange-gradient-horizontal);
		background-clip: text;
		-webkit-text-fill-color: transparent;

		&.violet {
			font-size: 29px;
			line-height: 121%;

			background: var(--color--violet-pink-orange-horizontal);
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	&-frame {
		width: 332px;
	}

	&-result {
		height: 0;
		width: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;

		position: relative;
		top: -300px;

		&-lock {
			margin-bottom: 30px;
			width: 34.5px;
			height: 48px;
		}

		&-text {
			width: 70%;
			display: block;
			text-align: center;
			margin-bottom: 35px;
			font-weight: 600;
			font-size: 21px;
			line-height: 29px;
			letter-spacing: -0.408px;
		}

		.button {
			padding: 12px 30px;
			width: 257px;
		}
	}

	&-border {
		height: 2px;
		width: 100%;
		background: var(--color--red-orange-gradient-horizontal);
		margin-bottom: 31px;
	}

	&-winner {
		margin-top: 69px;
		display: flex;
		flex-direction: column;
		align-items: center;

		&-award {
			margin-top: 3px;
			width: 35px;
			height: 35px;
			float: right; 
		}

		&-champion {
			height: 80px;
			width: 115px;
			margin-bottom: 15px;
		}

		&-label {
			width: 332px;
			margin: 0 0 15px;
			display: block;
			text-align: center;
			font-weight: 700;
			font-size: 28px;
			line-height: 38px;
		}

		&-available {
			margin: 0 0 30px;
			width: 168px;
			font-size: 16px;
			line-height: 25px;
			text-align: center;
			font-weight: normal;
		}
	}
}
