.review-list {
	margin: 0 auto 60px;
	max-width: 375px;

	&__rating {
		margin-bottom: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;

		&__stars {
			width: 88px;
		}

		&__label {
			font-weight: 400;
			font-size: 13px;
			line-height: 20px;
			opacity: 0.76;
		}
	}

	&__title {
		width: 300px;
		text-align: center;
		font-weight: 700;
		font-size: 26px;
		line-height: 30px;
		margin: 0 auto 35px;
	}
}
